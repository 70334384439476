import * as Sentry from "@sentry/vue";
import anime from "animejs";
import { VueHeadMixin, createHead } from "@unhead/vue";

import App from "./app.vue";
import { FontAwesomeIcon } from "./icons";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createApp } from "vue";

// :focus-visible polyfill
import "focus-visible/dist/focus-visible.min";

// Custom styles
import "./styles/_site.scss";

import { i18n } from "./locale/i18n";

import { isHandheld } from "@/clients/bowser";

if ("serviceWorker" in navigator && isHandheld()) {
  window.addEventListener("load", () => {
    console.log("Registering service worker");
    if (import.meta.env.VITE_ENV === "development") {
      navigator.serviceWorker.register("/dev-sw.js?dev-sw", { scope: "/" });
    } else {
      navigator.serviceWorker.register("/service-worker.js", { scope: "/" });
    }
  });
}

// This is so e2e tests can mock setLocation to change where user is redirected since window.location cannot be changed by cypress stub
(window as any).setLocation = function setLocation(url: string) {
  (window as any).location = url;
};

const app = createApp(App);

const head = createHead();
app.mixin(VueHeadMixin);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(i18n);
app.use(head);
app.component("Icon", FontAwesomeIcon);
app.component("font-awesome-icon", FontAwesomeIcon);
app.config.globalProperties.$animejs = anime;

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router })
    // Sentry replay would be good to have but haven't looked into how it actually works with GDPR
    // See https://docs.sentry.io/product/session-replay/protecting-user-privacy/
    // new Sentry.Replay(),
  ],
  sampleRate: 0.8,

  // We don't use performance in JS, but maybe don't discard everything
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app-api\.sigmastocks\.com\/api/]
});

// While scrolling, this temporarly disables all hover effects on everything
// eslint-disable-next-line func-names
(function () {
  const { body } = document;
  let timer: NodeJS.Timeout;

  if (!window.addEventListener || !body.classList || (window as any).Cypress) return;

  window.addEventListener(
    "scroll",
    () => {
      clearTimeout(timer);
      if (!body.classList.contains("disable-hover")) {
        body.classList.add("disable-hover");
      }
      timer = setTimeout(() => {
        body.classList.remove("disable-hover");
      }, 200);
    },
    false
  );
})();

app.mount("#app");
