<template>
  <v-app>
    <v-main>
      <div class="content">
        <router-view
          v-if="validBrowser && systemHealthy"
          v-slot="{ Component }"
        >
          <transition
            name="fade"
            mode="out-in"
          >
            <component :is="Component"
          /></transition>
        </router-view>
        <unsupported-browser
          v-if="!validBrowser"
          @ignoreWarning="() => ignoreWarning()"
        />
        <maintenance
          v-if="!systemHealthy && validBrowser"
          :system-health="systemHealth"
        />
      </div>
      <snackbar />
      <ios-install-pwa />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { isValidBrowser } from "@/clients/bowser";
import UnsupportedBrowser from "@/views/error-pages/unsupported-browser.vue";
import Snackbar from "@/components/snackbar.vue";
import { baseUrl } from "@/clients/config";
import Maintenance from "@/components/system-health.vue";
import IosInstallPwa from "./components/ios-install-pwa.vue";
import { HealthCheckClient, SystemHealth } from "./clients";
import { registerSW } from "virtual:pwa-register";
import { isHandheld } from "@/clients/bowser";
const healthCheckClient = new HealthCheckClient(baseUrl);

export default {
  name: "App",
  head: {
    title: "App",
    titleTemplate: "%s | Sigmastocks"
  },
  components: { UnsupportedBrowser, Snackbar, IosInstallPwa, Maintenance },
  data: () => ({
    validBrowser: true,
    serviceWorkerNeedRefresh: false,
    systemHealth: SystemHealth.Ok,
    updateSW: undefined as ((reloadPage?: boolean | undefined) => Promise<void>) | undefined
  }),
  computed: {
    systemHealthy(): boolean {
      return this.systemHealth === SystemHealth.Ok;
    }
  },
  mounted() {
    const self = this;
    if (isHandheld()) {
      this.updateSW = registerSW({
        onRegistered(r) {
          r &&
            setInterval(
              () => {
                console.log("Checking for service worker update");
                r.update();
              },
              60 * 60 * 1000
            ); //Hourly checks
        },
        onNeedRefresh() {
          self.serviceWorkerNeedRefresh = true;
        },
        onOfflineReady() {}
      });
    }
    this.verifyBrowser();
    this.checkSystemHealth();
  },
  watch: {
    serviceWorkerNeedRefresh: {
      handler(newValue) {
        if (newValue) {
          console.log(
            `Service worker updated event, safe to reload app ${this.$store.state.userStore.safeToReloadApp}`
          );
          if (this.$store.state.userStore.safeToReloadApp === true) {
            console.log("Reloading app");
            this.reloadApp();
          } else {
            setInterval(() => {
              if (this.$store.state.userStore.safeToReloadApp === true) {
                this.reloadApp();
              }
            }, 1000);
          }
        }
      }
    }
  },
  methods: {
    reloadApp() {
      if (!this.updateSW) {
        throw new Error("Refresh promise not set");
      }
      console.log("Reloading app");
      this.updateSW();
    },
    verifyBrowser() {
      this.validBrowser = isValidBrowser();
    },
    ignoreWarning() {
      this.validBrowser = true;
    },
    async checkSystemHealth() {
      try {
        const response = await healthCheckClient.getSystemHealth();
        if (!Number.isNaN(response)) {
          this.systemHealth = response;
        } else {
          this.systemHealth = SystemHealth.Down;
        }
      } catch (error) {
        this.systemHealth = SystemHealth.Down;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #fafafa;
  height: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
